import { createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods } from '@commonstock/client/src/constants'

export enum ReportContentType {
  Memo = 'MEMO_PLUS',
  Profile = 'USER',
  Comment = 'COMMENT',
  Trade = 'TRADE',
  Post = 'POST'
}

type ReportParams = {
  json: {
    content_type: ReportContentType
    content_uuid: string
    content_title?: string
    root_type?: string
    root_uuid?: string
  }
}

export const useReportAction = createUseFetchAction<void, ReportParams>({
  key: 'report',
  method: HttpMethods.Post,
  path: '/profile/report-content'
})
