import { track } from '../mixpanel'
import { Periods } from '@commonstock/common/src/types'
import { LeaderboardType, TrendingTabType } from '../../../components/TabSelector'
import { LeaderboardProfile } from '../../dashboard/TopInvestorsItem'
import { LeaderboardFilter } from '@commonstock/common/src/api/asset'
import { TrendingItemType } from '../../trending/GlobalTrending'
import { ChannelTypes } from '@commonstock/common/src/api/chat'

export enum ViewActionTargetType {
  Asset = 'Asset',
  DirectMessage = 'Direct Message',
  Leaderboard = 'Leaderboard',
  Navbar = 'Navbar',
  PrivateGroup = 'Private Group',
  Profile = 'Profile',
  PublicGroup = 'Public Group',
  Trending = 'Trending',
  Post = 'Post',
  Memo = 'Memo',
  Trade = 'Trade'
}

/*
 * Source covers when an object is viewed from inside a specific component
 * Page View event covers the page the user is on and is set as a super property
 */
export enum ViewActionSourceType {
  Leaderboard = 'Leaderboard',
  Trending = 'Trending'
}

export enum ViewActionFilterType {
  '1D' = '1D',
  '1W' = '1W',
  '1M' = '1M',
  '3M' = '3M',
  '1Y' = '1Y',
  'YTD' = 'YTD',
  'ALL' = 'ALL',
  follow = 'Follow',
  global = 'Global',
  channel = 'Channel'
}

type ViewActionObject = {
  target_type: ViewActionTargetType
  target_id?: string
  source_type?: ViewActionSourceType
  source_id?: string
  filters?: ViewActionFilterType[]
  detail?: string
  channel_member_count?: number
}

const leaderboardIDMap = {
  [LeaderboardType.Investing]: 'Investing',
  [LeaderboardType.Follower]: 'Follower Value'
}

const trendingIDMap = {
  [TrendingTabType.MostBought]: 'Most bought',
  [TrendingTabType.MostSold]: 'Most sold',
  [TrendingTabType.MostMentions]: 'Most mentions'
}

export function viewAction(data?: ViewActionObject) {
  track('View Action', { ...data })
}

export function trackLeaderboardViewAction(leaderboardType: LeaderboardType) {
  viewAction({
    target_type: ViewActionTargetType.Leaderboard,
    target_id: leaderboardIDMap[leaderboardType]
  })
}

export function trackTrendingViewAction(trendingTabType: TrendingTabType) {
  viewAction({
    target_type: ViewActionTargetType.Trending,
    target_id: trendingIDMap[trendingTabType]
  })
}

type LeaderboardProfileViewObject = {
  topInvestor: LeaderboardProfile
  leaderboardType: LeaderboardType
  period: Periods
  filter: LeaderboardFilter
}

export function trackLeaderboardProfileViewAction(data: LeaderboardProfileViewObject) {
  const filters = [ViewActionFilterType[data.period], ViewActionFilterType[data.filter]]
  viewAction({
    target_type: ViewActionTargetType.Profile,
    target_id: data.topInvestor.user.uuid,
    source_type: ViewActionSourceType.Leaderboard,
    source_id: leaderboardIDMap[data.leaderboardType],
    filters: filters
  })
}

export function trackTrendingAssetView(trendingTabType: TrendingTabType, trendingItem: TrendingItemType) {
  viewAction({
    target_type: ViewActionTargetType.Asset,
    target_id: `${trendingItem.trend.asset_symbol}:${trendingItem.trend.asset_type}`,
    source_type: ViewActionSourceType.Trending,
    source_id: trendingIDMap[trendingTabType]
  })
}

export function trackChannelViewAction(channel_type: string, channel_url: string, channel_members: number) {
  const target_type =
    channel_type === ChannelTypes.Direct
      ? ViewActionTargetType.DirectMessage
      : channel_type == ChannelTypes.Public
      ? ViewActionTargetType.PublicGroup
      : ViewActionTargetType.PrivateGroup
  viewAction({
    target_type: target_type,
    target_id: channel_url,
    channel_member_count: channel_members
  })
}

export enum NavbarTabType {
  Alerts = 'Alerts',
  Discover = 'Discover',
  Following = 'Following',
  Login = 'Login',
  Home = 'Home',
  Memo = 'Memo',
  Portfolio = 'Portfolio',
  Post = 'Post',
  Profile = 'Profile',
  Trending = 'Trending'
}

export function trackNavbarViewAction(tab: NavbarTabType) {
  viewAction({
    target_type: ViewActionTargetType.Navbar,
    target_id: tab
  })
}

export function trackPostViewAction(post_uuid: string) {
  viewAction({
    target_type: ViewActionTargetType.Post,
    target_id: post_uuid
  })
}

export function trackMemoViewAction(memo_uuid: string) {
  viewAction({
    target_type: ViewActionTargetType.Memo,
    target_id: memo_uuid
  })
}

export function trackTradeViewAction(trade_uuid: string) {
  viewAction({
    target_type: ViewActionTargetType.Trade,
    target_id: trade_uuid
  })
}
