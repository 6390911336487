import React, { useMemo, useRef, useEffect, useCallback } from 'react'
import Line from './Line'
import ReferenceLine from './ReferenceLine'
import { throttle } from 'throttle-debounce'
import { select, mouse, touch } from 'd3-selection'
import { useLineChart, DataPoint } from './LineChartContext'
import { Colors } from '../../theme/constants'
import { bisector } from 'd3-array'
import { useChartHover } from './ChartHoverContext'

function InnerLineChart() {
  const {
    width,
    height,
    margin,
    data,
    chartData,
    color,
    lineColor,
    xScale,
    strokeWidth,
    showBenchmark,
    dataKey,
    isFlatLine
  } = useLineChart()
  const { setHoverIndex } = useChartHover()

  const delay = 0
  const duration = 300

  const lineChartRef = useRef<SVGSVGElement>(null)
  const _setHoverIndex = useMemo(() => setHoverIndex && throttle(50, setHoverIndex), [setHoverIndex])

  const bisect = useCallback(
    (mx: number) => {
      const bisect = bisector<DataPoint, any>(d => d.i).left
      const indexAxis = xScale.invert(mx)
      const index = bisect(data, indexAxis, 1)
      const a = data[index - 1]
      const b = data[index]
      return b && indexAxis - a.i > b.i - indexAxis ? b : a
    },
    [data, xScale]
  )

  useEffect(() => {
    if (!lineChartRef.current || !_setHoverIndex) return
    const svg = select(lineChartRef.current)
    svg.on('mousemove touchmove', function() {
      const [pointerXPosition] = mouse(this) || touch(this, 0)
      const data = bisect(pointerXPosition - margin.left)
      _setHoverIndex(data.i)
    })

    svg.on('mouseleave touchend', () => {
      _setHoverIndex(null)
    })
  }, [_setHoverIndex, bisect, data, margin.left])

  return (
    <svg className="lineChartSvg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} ref={lineChartRef}>
      <g transform={`translate(${margin.left}, ${margin.top})`}>
        <ReferenceLine />
        {showBenchmark && chartData.benchmark && (
          <Line
            color={Colors.ChartsBenchmark}
            animationBegin={delay}
            animationDuration={duration}
            strokeWidth={1}
            dataKey={'b'}
          />
        )}
        {!isFlatLine && (
          <Line
            color={lineColor || color}
            animationBegin={delay}
            animationDuration={duration}
            strokeWidth={strokeWidth || 2}
            dataKey={dataKey}
          />
        )}
      </g>
    </svg>
  )
}

export default React.memo(InnerLineChart)
