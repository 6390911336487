import React, { MouseEvent, useCallback, useMemo } from 'react';
import Tooltip from '../../components/Tooltip';
import { Share } from '../../theme/NewIcons';
import { CardIcon, Menu, MenuItem } from '../../components/styles';
import { IconButton } from '../../composing/CSButton';
import { useEphemeralModal } from '../modal/Modal';
import { ShareType } from '../../../../common/src/api/link';
import { TextCaptionEmphasis } from 'src/composing/CSText';
import { SpacerH } from 'src/composing/Spacing';
import { CardLayoutTypes, isUpvoteLayout } from 'src/scopes/feed/constants';
import { flexCentered, relative } from 'src/theme/AtomicClasses';
import dynamic from 'next/dynamic';
import { isLoggedIn } from '@commonstock/common/src/auth';
import { JoinModalPublic } from 'src/scopes/public/JoinModalPublic';
import useLoggedOutModal from 'src/scopes/public/useLoggedOutModal';
import { useState } from 'react';
import { Routes } from 'src/scopes/nav/constants';
import useEventListener from '@commonstock/common/src/utils/useEventListener';
import { css } from '@linaria/core';
const ShareModal = dynamic(() => import('./ShareModal'));
type Props = {
  tradeGroup?: boolean;
  trade?: boolean;
  memo?: boolean;
  post?: boolean;
  uuid: string;
  disabled?: boolean;
  cardLayout?: CardLayoutTypes;
};

function ShareIcon({
  tradeGroup,
  trade,
  memo,
  post,
  uuid,
  disabled,
  cardLayout
}: Props) {
  const modal = useEphemeralModal();
  const loggedOutModal = useLoggedOutModal(`Share`);
  const [isOpen, setIsOpen] = useState(false);
  const [wasCopied, setWasCopied] = useState(false);
  const tip = useMemo(() => {
    if (disabled) return 'Share disabled';
    if (tradeGroup) return 'Share trades';
    if (trade) return 'Share trade';
    if (memo) return 'Share memo';
    if (post) return 'Share post';
    return '';
  }, [disabled, tradeGroup, trade, memo, post]);
  const onClickSharePreview = useCallback((e: MouseEvent) => {
    e.preventDefault();

    if (!isLoggedIn()) {
      loggedOutModal('Join', <JoinModalPublic />);
      return;
    }

    const shareModal = trade ? <ShareModal type={ShareType.TRADE} uuid={uuid} /> : memo ? <ShareModal type={ShareType.MEMO_PLUS} uuid={uuid} /> : tradeGroup ? <ShareModal type={ShareType.TRADE_GROUP} uuid={uuid} /> : post ? <ShareModal type={ShareType.POST} uuid={uuid} /> : null;

    if (shareModal) {
      modal(shareModal);
      setIsOpen(false);
    }

    e.stopPropagation();
  }, [memo, loggedOutModal, modal, post, trade, tradeGroup, uuid]);
  const onClickMenu = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isLoggedIn()) {
      loggedOutModal('Join', <JoinModalPublic />);
      return;
    }

    setIsOpen(prev => !prev);
  }, [loggedOutModal]);
  const onClickCopy = useCallback((e: MouseEvent) => {
    e.preventDefault();
    const shareLink = trade ? Routes.tradeDetail(uuid) : memo ? Routes.memoDetail(uuid) : tradeGroup ? Routes.tradeGroupDetail(uuid) : post ? Routes.postDetail(uuid) : '';
    navigator.clipboard.writeText(`${window.location.origin}${shareLink}`);
    setWasCopied(true);
  }, [memo, post, trade, tradeGroup, uuid]);
  useEventListener('click', (e: any) => {
    if (!e.target.closest('#share-content-menu')) {
      setIsOpen(false);
      setWasCopied(false);
    }
  });
  return <CardIcon id="#share-content-menu">
      <Tooltip tip={tip} disable={isOpen}>
        <div className={relative}>
          <IconButton className={flexCentered} onClick={disabled ? undefined : onClickMenu} disabled={disabled} aria-label={'Share content'}>
            <Share size={1.25} />
            {isUpvoteLayout(cardLayout) && <>
                <SpacerH rem={0.5} />
                <TextCaptionEmphasis>Share</TextCaptionEmphasis>
              </>}
          </IconButton>
          <Menu className={wrapperPadding} hidden={!isOpen}>
            <MenuItem onClick={onClickCopy} isPositive={wasCopied}>
              {wasCopied ? 'Link copied!' : 'Copy link'}
            </MenuItem>
            <MenuItem onClick={onClickSharePreview}>Share preview</MenuItem>
          </Menu>
        </div>
      </Tooltip>
    </CardIcon>;
}

export default ShareIcon;
const wrapperPadding = "w7ki1ga";

require("../../../.linaria-cache/packages/oxcart/src/scopes/share/ShareIcon.linaria.module.css");