import React, { useCallback, useState } from 'react'
import { More } from '../../theme/NewIcons'
import { IconButton } from '../../composing/CSButton'
import { Menu, MenuItem } from '../../components/styles'
import { useGetProfile } from '@commonstock/common/src/api/profile'
import { MuteToggleItem } from '../profile/ProfileButtons'
import { useAuth } from '../auth/AuthContext'
import FollowProfile from '../profile/FollowProfile'
import MuteTrades from './MuteTrades'
import useEventListener from '@commonstock/common/src/utils/useEventListener'
import { TradeGroupFeedItem } from '@commonstock/common/src/api/feed'
import { destructTradeFeedItem } from '../trade/utils'
import { useEphemeralModal } from '../modal/Modal'
import { ShareType } from '../../../../common/src/api/link'
import dynamic from 'next/dynamic'

const ShareModal = dynamic(() => import('../share/ShareModal'))

function TradeGroupCardActions({ tradeFeedItem }: { tradeFeedItem: TradeGroupFeedItem }) {
  let { user } = destructTradeFeedItem(tradeFeedItem)

  const [isOpen, setIsOpen] = useState(false)
  const { userUuid } = useAuth()
  const [userProfile] = useGetProfile({ meta: { user_uuid: tradeFeedItem.user_uuid } }, { paused: !isOpen })
  const isMyTrade = userUuid === tradeFeedItem.user_uuid
  user = userProfile ? { ...userProfile, uuid: userProfile.user_uuid } : user

  const onClickMenu = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(prev => !prev)
  }, [])

  useEventListener('click', (e: any) => !e.target.closest(`#actions-${tradeFeedItem.uuid}`) && setIsOpen(false))

  const modal = useEphemeralModal()
  const handleShare = useCallback(() => {
    modal(<ShareModal type={ShareType.TRADE_GROUP} uuid={tradeFeedItem.uuid} />)
  }, [modal, tradeFeedItem.uuid])

  if (!user) return null
  return (
    <div id={`actions-${tradeFeedItem.uuid}`}>
      <IconButton onClick={onClickMenu} aria-label={'Show more trade actions'}>
        <More active={isOpen} size={1.25} />
      </IconButton>
      <Menu hidden={!isOpen}>
        <MenuItem disabled={userProfile?.private} onClick={handleShare}>
          {userProfile?.private ? 'Share disabled' : 'Share trades'}
        </MenuItem>
        {!isMyTrade && (
          <>
            <FollowProfile profile={user} isDropdown />
            <MuteTrades tradeFeedItem={tradeFeedItem} />
            <MuteToggleItem profile={user} />
            {/* @NOTE: Allow reporting of trade group (batched trade) when BE ready */}
            {/*<ReportContentMenuItem
                  uuid={batchedTrade.uuid}
                  title={`${batchedTrade.user.username}'s trade`}
                  contentType={ReportContentType.Trade}
                />*/}
          </>
        )}
      </Menu>
    </div>
  )
}

export default TradeGroupCardActions
